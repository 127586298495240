import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
          edges {
            node {
              publicURL
              extension
              relativePath
              childImageSharp {
                original {
                  src
                }
                resize(width: 10, quality: 10, pngQuality: 10) {
                  src
                }
                sizes(maxWidth: 1280, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename.split('/').pop());
      });

      if (!image) { return null; }
        return (
          <img
            className="lazyload"
            alt={ props.alt }
            src={ image.node.childImageSharp.resize.src }
            data-src={ image.node.childImageSharp.original.src }
            data-srcset={ 
                `${image.node.childImageSharp.sizes.src} 640w,
                ${image.node.childImageSharp.original.src} 1920w` } />
        );
    }}
  />
)

export default Image
